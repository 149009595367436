export class Bank {
    _id: string;
    bankId: string;
    name: string;
    code: string;
    bankType: string;
    acc: string;
    person: string;
    groupBankId: Bank;
    bankAcc: string;
    company: string;

}