import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { of, throwError } from 'rxjs';
import { SESSION_TIMEOUT_SUBJECT } from '../config/const';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class Client {

  // readonly API_ROOT = environment.apiUrl;
  readonly API_ROOT = window.location.host.indexOf('localhost') > -1 ? environment.apiUrl: window.location.protocol + '//' + window.location.host + '/api/v1';
  
  // defaultOptions: any = null;

  // ngOnInit() {
  //   this.
    defaultOptions = {
      headers: {
        'content-type': 'application/json',
        authorization: `Bearer ${localStorage.getItem('token')}`,
        data: `${localStorage.getItem('user_data')}`
      },
    };
  // } 

  constructor(private httpClient: HttpClient) {}

  private handleError(errResponse) {
    if (errResponse.status === 401) {
      SESSION_TIMEOUT_SUBJECT.next(true);
    }
    // error can be reformat before sending to original invoke functiom
    return throwError(errResponse);
    // return of(Error(errResponse.statusText || 'API ERROR'));
  }

  public get(route, options = {}): Observable<any> {

    this.defaultOptions = {
      headers: {
        'content-type': 'application/json',
        authorization: `Bearer ${localStorage.getItem('token')}`,
        data: `${localStorage.getItem('user_data')}`
      },
    };

    // console.log('get ', JSON.stringify(this.defaultOptions))
    return this.httpClient.get(`${this.API_ROOT}/${route}`, {
      ...options,
      ...this.defaultOptions
    }).pipe(
      catchError(this.handleError)
    );
  }

  public post(route, data, options = {}) {
    console.log('post ', localStorage.getItem('token'))
    return this.httpClient.post(`${this.API_ROOT}/${route}`, data, {
      ...options,
      ...this.defaultOptions
    }).pipe(
      catchError(this.handleError)
    );
  }

  public put(route, data, options = {}) {
    console.log('put ', localStorage.getItem('token'))
    return this.httpClient.put(`${this.API_ROOT}/${route}`, data, {
      ...options,
      ...this.defaultOptions
    }).pipe(
      catchError(this.handleError)
    );
  }

  public delete(_id, options = {}) {
    console.log('delete ', localStorage.getItem('token'))
    return this.httpClient.delete(`${this.API_ROOT}/${_id}`, {
      ...options,
      ...this.defaultOptions
    }).pipe(
      catchError(this.handleError)
    );
  }
}
