import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Register Page'
    }
  },
  
  {
    path: 'print',
    loadChildren: './views/print/print.module#PrintModule'
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    children: [
      {
        path: 'base',
        loadChildren: './views/base/base.module#BaseModule'
      },
      {
        path: 'dbs',
        loadChildren: './views/dbs/dbs.module#DbsModule'
      },
      {
        path: 'employees',
        loadChildren: './views/employees/employees.module#EmployeesModule'
      },
      {
        path: 'weighing-sheet',
        loadChildren: './views/weighing-sheet/weighing-sheet.module#WeighingSheetModule'
      },
      {
        path: 'graded-leaf',
        loadChildren: './views/graded-leaf/graded-leaf.module#GradedLeafModule'
      },
      {
        path: 'graded-ii-leaf',
        loadChildren: './views/graded-2-leaf/graded-leaf.module#GradedLeafModule'
      },
      {
        path: 'bought-leaf-loan',
        loadChildren: './views/bought-leaf-loan/bought-leaf-loan.module#BoughtLeafLoanModule'
      },
      {
        path: 'deduction',
        loadChildren: './views/deductions/deductions.module#DeductionModule'
      },
      {
        path: 'advance',
        loadChildren: './views/advance/advance.module#AdvanceModule'
      },
      {
        path: 'advance-approve',
        loadChildren: './views/advance-approve/advance-approve.module#AdvanceApproveModule'
      },
      {
        path: 'manure',
        loadChildren: './views/manure/manure.module#ManureModule'
      },
      {
        path: 'bf-manure',
        loadChildren: './views/manure-bf/manure-bf.module#ManureBFModule'
      },
      {
        path: 'bfm-manure',
        loadChildren: './views/manure-bfm/manure-bfm.module#ManureBFMModule'
      },
      {
        path: 'chq-pay',
        loadChildren: './views/chq-pay/chq-pay.module#ChqPayModule'
      },
      {
        path: 'credit',
        loadChildren: './views/credit/credit.module#CreditModule'
      },
      {
        path: 'bf-debts',
        loadChildren: './views/debts-bf/debts-bf.module#DebtsBFModule'
      },
      {
        path: 'bfm-debts',
        loadChildren: './views/debts-bfm/debts-bfm.module#DebtsBFMModule'
      },
      {
        path: 'stamp',
        loadChildren: './views/stamp/stamp.module#StampModule'
      },
      {
        path: 'tea',
        loadChildren: './views/tea/tea.module#TeaModule'
      },
      {
        path: 'rice',
        loadChildren: './views/rice/rice.module#RiceModule'
      },
      {
        path: 'transport',
        loadChildren: './views/transport/transport.module#TransportModule'
      },
      {
        path: 'transport-adj',
        loadChildren: './views/transport-adj/transport-adj.module#TransportAdjModule'
      },
      {
        path: 'cf-coin',
        loadChildren: './views/cf-coin/cf-coin.module#CFCoinModule'
      },
      {
        path: 'factories',
        loadChildren: './views/factories/factories.module#FactoriesModule'
      },
      {
        path: 'routes',
        loadChildren: './views/routes/routes.module#RoutesModule'
      },
      {
        path: 'suppliers',
        loadChildren: './views/suppliers/suppliers.module#SuppliersModule'
      },
      {
        path: 'banks',
        loadChildren: './views/banks/banks.module#BanksModule'
      },
      {
        path: 'accounts',
        loadChildren: './views/accounts/accounts.module#AccountsModule'
      },
      {
        path: 'users',
        loadChildren: './views/users/users.module#UsersModule'
      },
      {
        path: 'options',
        loadChildren: './views/options/options.module#OptionsModule'
      },
      {
        path: 'process',
        loadChildren: './views/process/process.module#ProcessModule'
      },
      {
        path: 'issue/:type',
        loadChildren: './views/item/item.module#ItemModule'
      },
      {
        path: 'loan',
        loadChildren: './views/loan/loan.module#LoanModule'
      },
      {
        path: 'items',
        loadChildren: './views/items/items.module#ItemsModule',        
      },  
      {
        path: 'suphis',
        loadChildren: './views/dateform/dateform.module#DateformModule',        
      },       
    ]
  },
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
