import { ToastrService } from 'ngx-toastr';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';

import { UserService } from '../../service/user.service';
import { SupplierService } from '../../service/supplier.service';
import { BankService } from '../../service/bank.service';
import { TxdService } from '../../service/txd.service';
import { RouteService } from '../../service/route.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';

interface Label {
  visible: boolean;
  val: string;
}

interface Labels {
  tblHeader1: string;
  tblHeader2: string;
  lblSub: Label;
  lblPaymethod?: Label;
  lblPayable: Label;
  lblRecommended: Label;
  lblRouteToDate: Label;
  lableSupToDate: Label;
  lblHelp: Label;
  lblManure: Label;
  lblTotal: Label;
  lblFactory: Label;
  lblRoute: Label;
  lblDay: Label;
  lblChqNo: Label;
  lblWeight: Label;
}

interface Configuration {
  title: string;
  transactionType: string;
  advance: boolean;
  labels: Labels;
}

@Component({
  selector: 'app-tx-grid',
  templateUrl: 'tx-grid.component.html',
  styleUrls: ['tx-grid.component.scss']
})
export class TxGridComponent implements OnInit {

  public defaultConfigs: Configuration = {
    title: '',
    transactionType: '',
    advance: false,
    labels: {
      tblHeader1: 'Supplier',
      tblHeader2: 'Weight',
      lblSub: { val: 'Subsidy', visible: false },
      lblRecommended: { val: 'Recommended', visible: false },
      lblPayable: { val: 'Payable', visible: false },
      lblRouteToDate: { val: 'Route todate', visible: false },
      lableSupToDate: { val: 'Sup. todate', visible: false },
      lblHelp: {
        val: 'Press F12 key to enter into XL data import mode. This feature is available only for the ROOT user.',
        visible: false
      },
      lblManure: { val: 'No. Bags', visible: false },
      lblTotal: { val: 'Total', visible: false },
      lblFactory: { val: 'Factory', visible: false },
      lblRoute: { val: 'Route', visible: false },
      lblDay: { val: 'Day', visible: false },
      lblChqNo: { val: 'Chq No.', visible: false },
      lblWeight: { val: 'Weight', visible: true }
    }
  };
 
  _confifuration: Configuration;
  payable: any;

  @Input()
  set configuration(config: Configuration) {
    console.log('Configuration initializing', config);
    if (config) {
      // set default values for rest of the configurations
      this._confifuration = _.merge({}, this.defaultConfigs, config);
    } else {
      this._confifuration = this.defaultConfigs;
    }
    console.log('Configuration initialized', this._confifuration);
    this.init();
  }

  // tslint:disable-next-line:no-output-on-prefix
  @Output() onSave: EventEmitter<any> = new EventEmitter<any>();

  // tslint:disable-next-line:no-output-on-prefix
  @Output() onSelectDate: EventEmitter<any> = new EventEmitter<any>();

  _weighingTxTypes = ["Weighing Sheet","Graded Leaf","Graded II Leaf"];

  _total = null;
  _weight = null;
  _selectedIndex = -1;
  _selectedRoute : any;
  _selectedDate = null;
  _routeErrorMessage = null;
  _rows = Array.from(Array(20).keys());
  _mapValues: Map<string, number> = new Map();
  _userData: { date: Date, facNo: number } = {} as { date: Date, facNo: number };
  _txdHistory:any[] = null;
  _txd:any[] = null;

  _suppliers = [];
  _dates = [];
  _routes = [];
  _bank: string;
  _currentDate: string;

  _facSumToDate = 0;
  _routeSumToDate = 0;
  _supSumToDate = 0 ;

  _paginations = {
    itemsPerPage: 100,
    currentPage: 1,
  };

  constructor(private userService: UserService,
    private routeService: RouteService,
    private txdService: TxdService,
    private supplierService: SupplierService,
    private bankService: BankService,
    private toastr:ToastrService) {
    console.log('Tx Grid Component');
  }

  _routeSumCurrent = new FormControl('',[Validators.min(0)]);

  ngOnInit() {
    this._userData = this.userService.getUserFactoryAndDate();
    this._currentDate = moment(this._userData.date).format('YYYY-MM-DD');
    this._selectedDate = (new Date(this._userData.date)).getDate();
    this.getTotalByCriteria();
    this.getTotalByCriteriasup();
  }

  // Initialize the grid configuration
  private init(): void {
    this._userData = this.userService.getUserFactoryAndDate();
    if (this._confifuration) {
      const config = this._confifuration;
      if (config.labels.lblDay.visible) {
        this._dates = Array.from(Array(moment(this._userData.date).daysInMonth()).keys()).map(x => x + 1);
        console.log(this._selectedDate);
      }

      this.getRoutesByFactoryNo();
      this.generateRowValue();
    }
  }

  // TODO: Add, Save Buttons
  saveMapValue(event) {
    const data = [];
    const val = this._mapValues.get(this._suppliers[this._selectedIndex].supplierNo)
    if (!isNaN(val)) {
      if (this._weight != 0)         
        this._weight = this._weight + val
    }


    if (this._selectedRoute && this._weight >= 0 && this._selectedIndex >= 0) {
      const val = this._weight;
      console.log('weight', val);
      this._mapValues.set(this._suppliers[this._selectedIndex].supplierNo, val);
      this._weight = null;
      this._selectedIndex = -1;
      this.calculateTotal();
      this.getTotalByCriteria();
     // this.getTotalByCriteriasup();
    }



    if (this._selectedRoute == null) {
      this._routeErrorMessage = 'Route Required';
      return;
    }
    this._routeErrorMessage = null;
   // const data = [];
    this._mapValues.forEach((v, k) => {
      if(this._confifuration.labels.lblWeight.val === "Amount (Rs.)"){
        data.push({ supplier: k, amount: v})  
      } else if(this._confifuration.labels.lblWeight.val === "Weight"){
        data.push({ supplier: k, weight: v})
      }
      });


    const { factory } = JSON.parse(localStorage.getItem('user_data'));
    let date = '';
    if(this._weighingTxTypes.includes(this._confifuration.transactionType)){
      date = moment.parseZone(`${this._currentDate.substring(0, 4)}-${this._currentDate.substring(5, 7)}-${this._selectedDate}`).toISOString()
    }
    else{
      date = moment.parseZone(this._currentDate).startOf('month').toISOString();
    }
    const txds = {
      data, 
      factory: factory,
      route: this._selectedRoute,
      txType: this._confifuration.transactionType,
      date : date,
      status: 'Pending' }
    console.log(txds);
    this.txdService.save(txds)
      .subscribe(
        res => {
          console.log('data saved', res);
          this.toastr.success('Transactions Saved');
          this.getTotalByCriteriasup(this._selectedRoute); 
          this.getTotalByCriteriasup();
        //  this.saveRouteTotal();
        },
        err => {
          console.log('error saving: ', err);
          this.toastr.error('Error Occured !');
      });


  }

  getTotalByCriteriasup(route?:string,supplier?:string):any {
    const { factory } = JSON.parse(localStorage.getItem('user_data'));
    console.log('getTotalByCriteriasup:', this._confifuration.transactionType)
    const txType = this._confifuration.transactionType == "Advance" ? "Weighing Sheet": this._confifuration.transactionType
    this.txdService.getTotalByCriteria(this._currentDate,factory,txType,route,supplier)
      .subscribe(
        res => {
          if (res && res.length) {
           // if (!route && !supplier) { this._facSumToDate = res[0].total; }
          //  if (route && !supplier) { this._routeSumToDate = res[0].total; }
            if (route && supplier) { this._supSumToDate = res[0].total; }
          } else {
          //  if (!route && !supplier) { this._facSumToDate = 0; }
          //  if (route && !supplier) { this._routeSumToDate = 0; }
            if (route && supplier) { this._supSumToDate = 0; }
          }
        },
        err => {
          console.log('error retrieving sum: ', err);
          this.toastr.error('Error Occured !');
        });
  }

  getMapValue(key): any {
    return this._mapValues.has(key)
      ? this._mapValues.get(key)
      : '';
  } 

  selectCell(num: number): void {
    if (this._suppliers[num] == null) {
      return;
    }
    this._selectedIndex = num;
    const mapKey = this._suppliers[num].supplierNo;
    this._weight = this._mapValues.get(mapKey);    
    
    // console.log('sup: ' + document.getElementById(this._suppliers[num].supplierNo).offsetTop) 

    const ele = document.getElementById('x' +this._suppliers[num].supplierNo) as HTMLElement;
    console.log('sup: ' + num + ' top ' + document.getElementById('x' + this._suppliers[num].supplierNo).offsetTop + ' txt '  +document.getElementById('x'+this._suppliers[num].supplierNo).innerText);
    //document.getElementById(this._suppliers[num].supplierNo).offsetTop)
    ele.parentElement.scrollTop = document.getElementById('x' + this._suppliers[num].supplierNo).offsetTop - 40;
    this.setFocusToWeightInput();
    // console.log(this._suppliers[num]);
    this.getTotalByCriteria(this._selectedRoute,this._suppliers[num].supplierNo);
    this.getTotalByCriteriasup(this._selectedRoute,this._suppliers[num].supplierNo);
    this.getBankById(this._suppliers[num].bankId)
    if(this._confifuration.advance){     
      this.getTxdHistory(this._suppliers[num].supplierNo);
      // console.log('sup: ', this._suppliers[num].bankId)     
        // this.getPayable(this._suppliers[num].supplierNo);
    }
  }

  setFocusToWeightInput(): void {
    const ele = document
      .getElementById(this._confifuration.labels.lblWeight.val) as HTMLElement;
    if (ele) {
      ele.focus();
    }
  }

  private generateRowValue(startVal = 0): void {
    this._rows.forEach((ele, index) => this._rows[index] = startVal++);
    this._rows = Array.from(this._rows);
  }

  private getRoutesByFactoryNo(): void {
    const { facNo } = JSON.parse(localStorage.getItem('user_data'));
    this.routeService.getRoutesByFactoryNo(facNo)
      .subscribe(routes => {
        console.log('routes', routes);
        this._routes = routes;
      }, err => {
        console.log('Error getting routes: ', err);
        this.toastr.error('Error Occured during fetching routes!');
      });
  }

  // emit the selected values map
  clickOnSave(): void {
    this.onSave.emit({});
  }

  clickOnCancel(): void {
    console.log('click cancel');
    // TODO: Implement cancel operation
    this._selectedRoute = null;
    this._mapValues = new Map();
    this.calculateTotal();
  }

  selectDate(date: string): void {
    this.onSelectDate.emit(date);
  }

  getIfValueExist(index: number): any {
    const value = this._suppliers[index];
    if (value != null) {
      return value.supplierNo;
    }
    return '';
  }

  onChangeDate(date: Date): void {
    this._selectedDate = date;
    console.log('on change date', date);
    this.fetchTrasactionData();
    if(this._selectedRoute)this.getRouteTotal();
  }

  onChangeRoute(e): void {
    this._routeErrorMessage = null;
    console.log('onChangeRoute', this._selectedRoute, e);
    this._selectedRoute = e;
    this._selectedIndex = -1;

    if (this._paginations.currentPage > 1) {
      this._paginations.currentPage = 1;
      const startineValueOfCurrentPage = this._paginations.itemsPerPage * (this._paginations.currentPage);
      this.generateRowValue(startineValueOfCurrentPage - this._paginations.itemsPerPage);      
    }
    
    this.fetchTrasactionData();
    this.getTotalByCriteria(this._selectedRoute);
    this.getTotalByCriteriasup(this._selectedRoute);

    this._routeSumCurrent.setValue(null);this.getRouteTotal();
  }

  private fetchTrasactionData() {
    this._mapValues = new Map();
    this.calculateTotal();
    this.getSuppliersByRoute();
    this.getTxdsByRouteAndDate();
  }

  private calculateTotal(): void {
    var arr = Array.from(this._mapValues.values())
    var arr1 = []
    for (var i =0; i < arr.length; i++) {
      if (!isNaN(arr[i])) arr1.push(arr[i])
    }
    // console.log(this._mapValues.values())
    this._total = Array.from(arr1.values())
      .reduce((pre, cur) => (pre + cur) , 0);
    this._total = Math.round(this._total   * 100) / 100;
  }

  private getSuppliersByRoute(): void {
   if (this._selectedRoute) {
      this.supplierService.getSuppliersByRoute(this._selectedRoute)
      .subscribe(res => {
        console.log('routes', res);
        this._suppliers = res;
      }, err => {
        console.log('error fetching routes: ', err);
        this.toastr.error('Error Occured during fetching suppliers!');
      });
    }
  }

  private getBankById(bankId: number): void {
    this._bank = ""
    if (bankId) {
       this.bankService.getBankbyBankId(bankId)
       .subscribe(res => {
        //  console.log('bank:', res[0].name);
          if (res && res.length) {
            this._bank = res[0].name;
          } 
       }, err => {
         console.log('error fetching routes: ', err);
         this.toastr.error('Error Occured during fetching bank!');
       });
     }
   }

  // TODO: Validate dates
  private getTxdsByRouteAndDate(): void {
    let date = '';
    let txType = this._confifuration.transactionType
    console.log('txType:', txType)
    
    if (this._selectedDate && this._selectedRoute) {
      if(this._weighingTxTypes.includes(this._confifuration.transactionType)){
        date = moment.parseZone(`${this._currentDate.substring(0, 4)}-${this._currentDate.substring(5, 7)}-${this._selectedDate}`).toISOString()
      } else{
        date = moment.parseZone(this._currentDate).startOf('month').format("YYYY-MM-DD");
      }     
      console.log(date);
      this.txdService.getTxdsByRouteTxTypeAndDate(date, this._selectedRoute, txType)
        .subscribe(txds => {
          console.log('txds', txds);
          if (txds && txds.length > 0) {
            const txdMap = new Map();
            txds.forEach(txd => {
              txdMap.set(txd.supplier, txd.weight || txd.amount);
            });
            this._mapValues = txdMap;
            this.calculateTotal();
          } else {
            this._mapValues = new Map();
          }
        }, err => {
          console.log('error txds routes: ', err);
          this.toastr.error('Error Occured !');
        });
    }
  }

  next(): void {
    if (this._suppliers.length > (this._paginations.itemsPerPage * this._paginations.currentPage)) {
      console.log('next page');
      this.generateRowValue(this._paginations.itemsPerPage * this._paginations.currentPage);
      this._paginations.currentPage++;
      console.log('Grid Component', this._rows, this._paginations);
    } else {
      console.log('no next page');
    }
  }

  back(): void {
    if (this._paginations.currentPage > 1) {
      console.log('back page');
      const startineValueOfCurrentPage = this._paginations.itemsPerPage * (this._paginations.currentPage - 1);
      this.generateRowValue(startineValueOfCurrentPage - this._paginations.itemsPerPage);
      this._paginations.currentPage--;
      console.log('Grid Component', this._rows, this._paginations, startineValueOfCurrentPage);
    } else {
      console.log('no back pages');
    }
  }

  save(): void {
    if (this._selectedRoute == null) {
      this._routeErrorMessage = 'Route Required';
      return;
    }
    this._routeErrorMessage = null;
    const data = [];
    this._mapValues.forEach((v, k) => {
      if(this._confifuration.labels.lblWeight.val === "Amount (Rs.)"){
        data.push({ supplier: k, amount: v})  
      }
      else if(this._confifuration.labels.lblWeight.val === "Weight"){
        data.push({ supplier: k, weight: v})
      }
      });
    const { factory } = JSON.parse(localStorage.getItem('user_data'));
    let date = '';
    if(this._weighingTxTypes.includes(this._confifuration.transactionType)){
      date = moment.parseZone(`${this._currentDate.substring(0, 4)}-${this._currentDate.substring(5, 7)}-${this._selectedDate}`).toISOString()
    }
    else{
      date = moment.parseZone(this._currentDate).startOf('month').toISOString();
    }
    const txds = {
      data, 
      factory: factory,
      route: this._selectedRoute,
      txType: this._confifuration.transactionType,
      date : date,
      status: 'Pending' }
    console.log(txds);
    this.txdService.save(txds)
      .subscribe(
        res => {
          console.log('data saved', res);
          this.toastr.success('Transactions Saved');
          this.getTotalByCriteria(this._selectedRoute); 
          this.getTotalByCriteria();
          this.saveRouteTotal();
        },
        err => {
          console.log('error saving: ', err);
          this.toastr.error('Error Occured !');
      });
  }

  getTxdHistory(supNo:string){
    this._txdHistory = null;
    this.txdService.getTxdHistory(this._selectedRoute,supNo,this._currentDate)
      .subscribe((res)=>{
        this._txdHistory = res;
        // console.log(this._txdHistory.length);
        if (this._txdHistory.length > 0 ) {
          this.payable = this.balance(this._txdHistory[0]);
        
       }
      })
  }

  getPayable(supNo:string){
    this._txd = null;
    console.log("sup"+supNo)
    this.txdService.getTxdPay(this._selectedRoute,supNo,this._currentDate)
      .subscribe((res)=>{
        this._txd = res;
        console.log('pay: ' + JSON.stringify(this._txd));
      })
  }

  balance(row: any):any {
    return row.gross - row.manSubs - row.bfManure - row.manure -row.tea- row.rice - row.bfDebit - row.advance - row.transport- row.other- row.loan+ row.credit;
  }

  getTotalByCriteria(route?:string,supplier?:string):any {
    const { factory } = JSON.parse(localStorage.getItem('user_data'));
    //"Graded Leaf"
    //"Graded II Leaf"
    //'Weighing Sheet'; //
    const txType = this._confifuration.transactionType;
    this.txdService.getTotalByCriteria(this._currentDate,factory,txType,route,supplier)
      .subscribe(
        res => {
          if (res && res.length) {
            if (!route && !supplier) { this._facSumToDate = res[0].total; }
            if (route && !supplier) { this._routeSumToDate = res[0].total; }
            if (route && supplier) { this._supSumToDate = res[0].total; }
          } else {
            if (!route && !supplier) { this._facSumToDate = 0; }
            if (route && !supplier) { this._routeSumToDate = 0; }
            if (route && supplier) { this._supSumToDate = 0; }
          }
        },
        err => {
          console.log('error retrieving sum: ', err);
          this.toastr.error('Error Occured !');
        });
  }

  getRouteTotal(){
    const { factory } = JSON.parse(localStorage.getItem('user_data'));
    const date = moment.parseZone(`${this._currentDate.substring(0, 4)}-${this._currentDate.substring(5, 7)}-${this._selectedDate}`).format('YYYY-MM-DD');
    this.txdService.getTxdByCriteria(date,factory,'Route Total(D)',this._selectedRoute,)
      .subscribe(
        res => {
          if(res){ this._routeSumCurrent.setValue(res.amount); }
          else{ this._routeSumCurrent.setValue(null); }
        },
        err =>{
          console.log('error retriving total ', err);
        }
      )
  }
  
  saveRouteTotal(e?:Event){
    const { factory } = JSON.parse(localStorage.getItem('user_data'));
    const date = moment.parseZone(`${this._currentDate.substring(0, 4)}-${this._currentDate.substring(5, 7)}-${this._selectedDate}`);
    const txd = {
      factory: factory,
      route: this._selectedRoute,
      txType: "Route Total(D)",
      date : date,
      supplier: "-2",
      amount: this._routeSumCurrent.value
    }
    if(this._routeSumCurrent.valid){
      this.txdService.createOne(txd)
      .subscribe(
        res => {
          console.log('route total saved', res);
          if(e && e.type === 'keydown') this.toastr.info('Total value saved');
        },
        err => {
          console.log('error saving total: ', err);
      });
    }
    
  }
}
