export const navItems = [
  // {
  //   name: 'Dashboard',
  //   url: '/dashboard',
  //   icon: 'icon-speedometer',
  //   badge: {
  //     variant: 'info',
  //     text: 'NEW'
  //   }
  // },
  // {
  //   title: true,
  //   name: 'Theme'
  // },
  // {
  //   name: 'Colors',
  //   url: '/theme/colors',
  //   icon: 'icon-bell'
  // },
  // {
  //   name: 'Typography',
  //   url: '/theme/typography',
  //   icon: 'icon-pencil'
  // },
  // {
  //   title: true,
  //   name: 'Components'
  // },
  {
    name: 'Master',
    url: '/base',
    icon: 'icon-equalizer',
    children: [
      {
        name: 'Database',
        url: '/dbs',
        icon: 'icon-puzzle',
      },
      
      {
        name: 'Users',
        url: '/users',
        icon: 'icon-people'
      },
      {
        name: 'Options',
        url: '/options',
        icon: 'icon-options'
      },
      {
        name: 'Employees',
        url: '/employees',
        icon: 'icon-people'
      },
      {
        name: 'Factory',
        url: '/factories',
        icon: 'icon-home'
      },
      {
        name: 'Route',
        url: '/routes',
        icon: 'icon-graph'
      },
      {
        name: 'Bank',
        url: '/banks',
        icon: 'icon-globe'
      },
      {
        name: 'Account',
        url: '/accounts',
        icon: 'icon-calculator'
      },
      {
        name: 'Supplier',
        url: '/suppliers',
        icon: 'icon-basket-loaded'
      },
      {
        name: 'Items',
        url: '/items',
        icon: 'icon-user'
      },
    ]
  },
  {
    name: 'Transactions',
    url: '/transactions',
    icon: 'icon-layers',
    children: [
      {
        name: 'Weighing Sheet',
        url: '/weighing-sheet',
        icon: 'icon-envelope-letter'
      },
      {
        name: 'Graded Leaf',
        url: '/graded-leaf',
        icon: 'icon-envelope-letter'
      },
      {
        name: 'Graded II Leaf',
        url: '/graded-ii-leaf',
        icon: 'icon-envelope-letter'
      },
      {
        name: 'Bought Leaf Loan',
        url: '/bought-leaf-loan',
        icon: 'icon-envelope-letter'
      },
      {
        name: 'Deduction',
        url: '/deduction',
        icon: 'icon-envelope-letter'
      },
      {
        name: 'Advance',
        url: '/advance',
        icon: 'icon-envelope-letter'
      },
      {
        name: 'Advance-Approval',
        url: '/advance-approve',
        icon: 'icon-envelope-letter'
      },
      {
        name: 'Manure',
        url: '/manure',
        icon: 'icon-envelope-letter'
      },
      {
        name: 'BF Manure',
        url: '/bf-manure',
        icon: 'icon-envelope-letter'
      },
      {
        name: 'Modified BF Manure',
        url: '/bfm-manure',
        icon: 'icon-envelope-letter'
      },
      {
        name: 'Cheque Payment',
        url: '/chq-pay',
        icon: 'icon-envelope-letter'
      },
      {
        name: 'Credit',
        url: '/credit',
        icon: 'icon-envelope-letter'
      },
      {
        name: 'BF Debits',
        url: '/bf-debts',
        icon: 'icon-envelope-letter'
      },
      {
        name: 'Modified BF Debits',
        url: '/bfm-debts',
        icon: 'icon-envelope-letter'
      },
      {
        name: 'Stamp',
        url: '/stamp',
        icon: 'icon-envelope-letter'
      },
      {
        name: 'Tea',
        url: '/tea',
        icon: 'icon-envelope-letter'
      },
      {
        name: 'Rice',
        url: '/rice',
        icon: 'icon-envelope-letter'
      },
      {
        name: 'Transport',
        url: '/transport',
        icon: 'icon-envelope-letter'
      },
      {
        name: 'Transport Adj',
        url: '/transport-adj',
        icon: 'icon-envelope-letter'
      },
      {
        name: 'C/F Coins',
        url: '/cf-coin',
        icon: 'icon-envelope-letter'
      }
    ]
  },{
    name: 'Issue',
    url: '/issue',
    icon: 'icon-puzzle',
    children: [
      {
        name: 'Tea',
        url: '/issue/tea',
        icon: 'icon-star'
      },
      {
        name: 'Rice',
        url: '/issue/rice',
        icon: 'icon-star'
      },
      {
        name: 'Manure',
        url: '/issue/manure',
        icon: 'icon-star'
      },
    ]
  },{
    name: 'Loan',
    url: '/loan',
    icon: 'icon-puzzle',
  },{
    name: 'Options',
    url: '/options',
    icon: 'icon-puzzle',
    children: [
      {
        name: 'Process',
        url: '/process',
        icon: 'icon-star'
      },
      /*{
        name: 'Dbs',
        url: '/dbs',
        icon: 'icon-puzzle',
      },*/
    ]
  },{
    name: 'Reports',
    url: '/reports',
    icon: 'icon-puzzle',
    children: [
      {
        name: 'Supplier History',
        url: '/suphis',
        icon: 'icon-star'
      },
      /*{
        name: 'Dbs',
        url: '/dbs',
        icon: 'icon-puzzle',
      },*/
    ]
  }
  //     {
  //       name: 'Forms',
  //       url: '/base/forms',
  //       icon: 'icon-puzzle'
  //     },
  //     {
  //       name: 'Cards',
  //       url: '/base/cards',
  //       icon: 'icon-puzzle'
  //     },
  //     {
  //       name: 'Carousels',
  //       url: '/base/carousels',
  //       icon: 'icon-puzzle'
  //     },
  //     {
  //       name: 'Collapses',
  //       url: '/base/collapses',
  //       icon: 'icon-puzzle'
  //     },
  //     {
  //       name: 'Forms',
  //       url: '/base/forms',
  //       icon: 'icon-puzzle'
  //     },
  //     {
  //       name: 'Pagination',
  //       url: '/base/paginations',
  //       icon: 'icon-puzzle'
  //     },
  //     {
  //       name: 'Popovers',
  //       url: '/base/popovers',
  //       icon: 'icon-puzzle'
  //     },
  //     {
  //       name: 'Progress',
  //       url: '/base/progress',
  //       icon: 'icon-puzzle'
  //     },
  //     {
  //       name: 'Switches',
  //       url: '/base/switches',
  //       icon: 'icon-puzzle'
  //     },
  //     {
  //       name: 'Tables',
  //       url: '/base/tables',
  //       icon: 'icon-puzzle'
  //     },
  //     {
  //       name: 'Tabs',
  //       url: '/base/tabs',
  //       icon: 'icon-puzzle'
  //     },
  //     {
  //       name: 'Tooltips',
  //       url: '/base/tooltips',
  //       icon: 'icon-puzzle'
  //     }
  //   ]
  // },
  // {
  //   name: 'Buttons',
  //   url: '/buttons',
  //   icon: 'icon-cursor',
  //   children: [
  //     {
  //       name: 'Buttons',
  //       url: '/buttons/buttons',
  //       icon: 'icon-cursor'
  //     },
  //     {
  //       name: 'Dropdowns',
  //       url: '/buttons/dropdowns',
  //       icon: 'icon-cursor'
  //     },
  //     {
  //       name: 'Brand Buttons',
  //       url: '/buttons/brand-buttons',
  //       icon: 'icon-cursor'
  //     }
  //   ]
  // },
  // {
  //   name: 'Charts',
  //   url: '/charts',
  //   icon: 'icon-pie-chart'
  // },
  // {
  //   name: 'Icons',
  //   url: '/icons',
  //   icon: 'icon-star',
  //   children: [
  //     {
  //       name: 'CoreUI Icons',
  //       url: '/icons/coreui-icons',
  //       icon: 'icon-star',
  //       badge: {
  //         variant: 'success',
  //         text: 'NEW'
  //       }
  //     },
  //     {
  //       name: 'Flags',
  //       url: '/icons/flags',
  //       icon: 'icon-star'
  //     },
  //     {
  //       name: 'Font Awesome',
  //       url: '/icons/font-awesome',
  //       icon: 'icon-star',
  //       badge: {
  //         variant: 'secondary',
  //         text: '4.7'
  //       }
  //     },
  //     {
  //       name: 'Simple Line Icons',
  //       url: '/icons/simple-line-icons',
  //       icon: 'icon-star'
  //     }
  //   ]
  // },
  // {
  //   name: 'Notifications',
  //   url: '/notifications',
  //   icon: 'icon-bell',
  //   children: [
  //     {
  //       name: 'Alerts',
  //       url: '/notifications/alerts',
  //       icon: 'icon-bell'
  //     },
  //     {
  //       name: 'Badges',
  //       url: '/notifications/badges',
  //       icon: 'icon-bell'
  //     },
  //     {
  //       name: 'Modals',
  //       url: '/notifications/modals',
  //       icon: 'icon-bell'
  //     }
  //   ]
  // },
  // {
  //   name: 'Widgets',
  //   url: '/widgets',
  //   icon: 'icon-calculator',
  //   badge: {
  //     variant: 'info',
  //     text: 'NEW'
  //   }
  // },
  // {
  //   divider: true
  // },
  // {
  //   title: true,
  //   name: 'Extras',
  // },
  // {
  //   name: 'Pages',
  //   url: '/pages',
  //   icon: 'icon-star',
  //   children: [
  //     {
  //       name: 'Login',
  //       url: '/login',
  //       icon: 'icon-star'
  //     },
  //     {
  //       name: 'Register',
  //       url: '/register',
  //       icon: 'icon-star'
  //     },
  //     {
  //       name: 'Error 404',
  //       url: '/404',
  //       icon: 'icon-star'
  //     },
  //     {
  //       name: 'Error 500',
  //       url: '/500',
  //       icon: 'icon-star'
  //     }
  //   ]
  // },
  // {
  //   name: 'Download CoreUI',
  //   url: 'http://coreui.io/angular/',
  //   icon: 'icon-cloud-download',
  //   class: 'mt-auto',
  //   variant: 'success'
  // },
  // {
  //   name: 'Try CoreUI PRO',
  //   url: 'http://coreui.io/pro/angular/',
  //   icon: 'icon-layers',
  //   variant: 'danger'
  // }
];
