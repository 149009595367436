import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../service/auth.service';
import { User } from '../../model/user';
import { take } from 'rxjs/operators';
import { BlrService } from '../../service/blr.service';

import * as moment from 'moment';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html'
})
export class LoginComponent implements OnInit {
  username = '';
  password = '';
  date: string = moment(new Date()).format('YYYY-MM-DD');
  error = false;
  errorMessage = '';
  loginForm: FormGroup;
// date1 = new Date()


  readonly user = {
    username: '',
    password: '',
    date: this.date,
    factory: ''
  };

  constructor(private router: Router,
    private authService: AuthService,
    private blrService: BlrService) {}

  ngOnInit() {
    this.initLoginForm();
    const dateElement = document.getElementById('date') as HTMLInputElement;
    if (dateElement) {
      dateElement.max = moment(new Date()).add(1, 'day').format('YYYY-MM-DD');
    }
  }

  initLoginForm(): void {
    this.loginForm = new FormGroup({
      username: new FormControl(this.user.username, [Validators.required]),
      password: new FormControl(this.user.password, [Validators.required]),
      date: new FormControl(this.user.date, [Validators.required]),
      // factory: new FormControl(this.user.factory, [Validators.required])
    });
  }

  login() {
    const user: User = this.loginForm.value;
    if (this.loginForm.valid) {
      this.authService.authenticate(user)
        .subscribe((res) => {
          console.log(res);
          localStorage.setItem('token', res.token);
          // alert(localStorage.getItem('token'))
          const { date } = user;
          const { user: { factory: { facNo,_id } }} = res;

          const userData = {
            date,
            facNo: facNo,
            factory:_id,
            companyName: res.user.factory.company,
            username: user.username,
            roles: res.user.roles,
            
          };

          
          // timer.subscribe(t => { 
            const formValues = this.loginForm.value;

            if (this.loginForm.valid){
              localStorage.setItem('user_data', JSON.stringify(userData));
              console.log('user details', userData);
              this.authService.initTimerForTokenExpired();
              this.router.navigateByUrl('/weighing-sheet')
              this.blrService.processParas(this.loginForm.value)
         
              .subscribe((res) => {              
                console.log(`Para Saved`);
              }, err => {
                console.log('Error Saving Para');
              });
            }             
          // });
          
        }, err => {
          console.log('authentication failed.', err);
          if (err.status === 400) {
            this.error = true;
            this.errorMessage = err.error.message;
          }
        });
    } else {
      Object.keys(this.loginForm.controls)
        .forEach(field => {
          const control = this.loginForm.get(field);
          control.markAsDirty({ onlySelf: true });
        });
    }

   //  const formValues = this.loginForm.value;

  // if (this.loginForm.valid){
  //   this.blrService.processParas(this.loginForm.value)

  //   .subscribe((res) => {
      
  //     console.log(`Para Saved`);
  //   }, err => {
  //     console.log('Error Saving Para');
  //   });
  // } 


  }

  lman() {        
    window.location.href = 'https://webmail.siyothsoft.com/lman/login';
  }

  private initTimeout(): void {
    // const timeout = this.authService.initTimer();
    // timeout
    // // .pipe(
    // //   take(1)
    // // )
    // .subscribe(() => {
    //   console.log('TOKEN EXPIRED');
    //   alert('Session expired.');
    //   this.router.navigateByUrl('/login');
    // }, err => {
    //   console.log('error: ', err);
    // });
  }
}
