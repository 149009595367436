import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Route } from '../model/route';
import { RouteFetchType } from '../model/fetch-data-types';
import { Client } from './client.service';

const ROUTES = `routes`;  // URL to web api

@Injectable({
  providedIn: 'root'
})

export class RouteService {

  constructor(private client: Client) { }

    addRoute (route: Route): Observable<Route> {
      return <Observable<Route>><unknown>this.client.post(ROUTES, route);
    }

    updateRoute (route: Route): Observable<Route> {
      return <Observable<Route>> <unknown>this.client.put(ROUTES, route);
    }

    updateAllRoutes (route: any): any {
      const url1 = `${ROUTES}/all`;
      return this.client.put(url1, route);
    }

    deleteRoute(route: Route): Observable<Route> {
      // if (route._id == null) return route;
      console.log('route._id: ' + route._id);
      const url1 = `${ROUTES}/${route._id}`;
      return <Observable<Route>> <unknown>this.client.delete(url1);
    }

    /** GET hero by id. Will 404 if id not found */
    getRoute(name: String): Observable<Route> {
      const url1 = `${ROUTES}/${name}`;
      return <Observable<Route>> this.client.get(url1);
    }

    // get routes by factoryNumber
    getRoutesByFactoryNo(facNo: number): Observable<Route[]> {
      return <Observable <Route[]>> this.client.get(`${ROUTES}/byFacNo/${facNo}`);
    }

    

    getDummyRoute(): Route {
      return {
        _id: '',
        route: '',
        name: '',
        trRate: '',
        routeRate: '',
        gradedRate: '',
        gradedRate1: '',
        facNo: {
          _id: '',
          facNo: '',
          address1: '',
          address2: '',
          address3: '',
          address4: '',
          company: ''},
        cf: '',
      };
    }

    getRoutes(pageNum): Observable<RouteFetchType> {
      const _url = (pageNum) ? `${ROUTES}?page=${pageNum}` : ROUTES;
      return <Observable<RouteFetchType>> this.client.get(_url);
    }

    getRoutesWithPagination(facNo: number,pageNum:number,pageSize?:number): Observable<RouteFetchType> {
      let params = {page:pageNum,pageSize:pageSize || ''};
      const _url=  `${ROUTES}/byFactoryNo/${facNo}/`;
      return <Observable<RouteFetchType>> this.client.get(_url,{params:params});
    }
}
