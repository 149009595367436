import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Route } from '../model/route';
import { Supplier } from '../model/supplier';
import { RouteFetchType } from '../model/fetch-data-types';
import { Client } from './client.service';
import { Bank } from '../model/bank';
import { Txd } from '../model/txd';
import { Voucher } from '../model/voucher';
import { Account } from '../model/account';



const ROUTES = `load/routes`;  // URL to web api
const SUPPLIERS= `load/suppliers1`;
const BANKS = 'load/banks1';
const VOUCHERS = 'load/vouchers';
const TXDS = 'load/txds';
const PARA = 'load/para'
const ACCOUNTS = 'load/accounts'
@Injectable({
    providedIn: 'root'
  })
  
  export class BlrService {
  
    constructor(private client: Client) { }


      processRoutes(route: Route): Observable<Route> {

        return <Observable<Route>>this.client.get(ROUTES, route);
      }

      processSuppliers(route: Supplier): Observable<Supplier> {
        return <Observable<Supplier>>this.client.post(SUPPLIERS, route);
      }

      processBanks(route: Bank): Observable<Bank> {

        return <Observable<Bank>>this.client.get(BANKS, route);
      }
      processVouchers(route: Voucher): Observable<Voucher> {

        return <Observable<Voucher>>this.client.get(VOUCHERS, route);
      }

      processTxds(route: Txd): Observable<Txd> {

        return <Observable<Txd>>this.client.get(TXDS, route);
      }
      processParas(route: any): Observable<any> {

        return <Observable<any>>this.client.get(PARA, route);
      }
      processAccounts(route: Account): Observable<Account> {

        return <Observable<Account>>this.client.get(ACCOUNTS, route);
      }

  }
  