import { SupplierFetchType } from './../model/fetch-data-types';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Supplier } from '../model/supplier';
import { Client } from './client.service';

const SUPPLIERS = `suppliers`;  // URL to web api

@Injectable({
  providedIn: 'root'
})
export class SupplierService {

  constructor(private client: Client) { }

  getDummySupplier(): Supplier {
    return {
      _id: '',
      supplierNo: '',
      route: {
        _id: '',
        route: ''
      },
      name: '',
      remarks: '',
      regNo: '',
      regDate: '',
      acerage: '',
      payMethodId: '',
      payMethodAdvId: '',
      trRate: '',
      address4: '',
      address3: '',
      address2: '',
      address1: '',
      cf: false,
      bankId: '',
      account: '',
      companyAcc: '',
      forceAccTran: false,
      lastChange: '',
      bankName: '',
      mobile: '',
      nic: '',
//    empType: { _id: '', name: '' }
    };
  }

  addSupplier (supplier: Supplier): Observable<Supplier> {
    return <Observable<Supplier>> <unknown>this.client.post(SUPPLIERS, supplier);
  }

  updateSupplier (supplier: Supplier): Observable<Supplier> {
    return <Observable<Supplier>><unknown>this.client.put(SUPPLIERS, supplier);
  }

  deleteSupplier(supplier: Supplier) {
    // if (supplier._id == null) return supplier;
    const url = `${SUPPLIERS}/${supplier._id}`;
    return this.client.delete(url);
  }

    /** GET hero by id. Will 404 if id not found */
  getSupplier(name: String): Observable<Supplier> {
    const url = `${SUPPLIERS}/${name}`;
    return <Observable<Supplier>> this.client.get(url);
  }

  getSuppliers(pageNum: number, limit: number): Observable<{suppliers: any[], count: number, page: number, pages: number }> {
    const url = (pageNum) ? `suppliers?page=${pageNum}` : SUPPLIERS;
    return <Observable<{ suppliers: any[], count: number, page: number, pages: number }>> this.client.get(url);
  }

  getSuppliersByRoute(route: string): Observable<Supplier[]> {
    return <Observable<Supplier[]>>this.client.get(`${SUPPLIERS}/byRoute/${route}`);
  }

  filterSuppliers(route: string,query:string,page: number, pageSize: number): Observable<SupplierFetchType> {
    return <Observable<SupplierFetchType>> this.client.get(`${SUPPLIERS}?_search=${query}&route=${route}&page=${page}&pageSize=${pageSize}`);
  }
}
