import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Client } from './client.service';
import { Bank } from '../model/bank';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json'})
};

@Injectable({
  providedIn: 'root'
})
export class BankService {

  constructor(private client: Client) { }

    getDummyBank(): Bank {
      return {
        _id: '',
        bankId: '',
        name: '',
        code: '',
        bankType: '1',
        acc: '',
        person: '',
        groupBankId: new Bank(),
        bankAcc: '', 
        company: "0"
//        empType: { _id: '', name: '' }
      };
    }

    addBank (bank: Bank): Observable<Bank> {
      return <Observable<Bank>> <unknown>this.client.post(`banks`, bank);
    }

    updateBank (bank: Bank): Observable<Bank> {
      return <Observable<Bank>> <unknown>this.client.put(`banks`, bank);
    }

    deleteBank(bank: Bank) {
      return <Observable<Bank>> <unknown>this.client.delete(`banks/${bank._id}`);
    }

     /** GET hero by id. Will 404 if id not found */
    getBank(name: String): Observable<Bank> {
      return <Observable<Bank>> this.client.get(`banks/${name}`);
    }

    getBankbyBankId(bankId: number): Observable<Bank[]> {
      return <Observable<Bank[]>> this.client.get(`bankBybankId/${bankId}`);
    }

  getBanks(pageNum?: number, sort?: string): Observable<{ banks: any[], count: number, page: number, pages: number }> {
    var url = (pageNum) ? `page=${pageNum}` : ``;
    url = sort ? url + `&sort=${sort}`: url;

    var url = (pageNum) ? `banks?page=${pageNum}` : `banks`;
    url = sort ? url + `&sort=${sort}`: url;

    return <Observable<{ banks: any[], count: number, page: number, pages: number }>>this.client.get(url);
  }
}
