import { TxdFetchType } from './../model/fetch-data-types';
import { Loan } from './../model/loan';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Client } from './client.service';
import { Txd } from '../model/txd';

const TXD = 'txd';
const LOAN = 'loan'
const Advance = 'advance'
@Injectable({
  providedIn: 'root'
})
export class TxdService {

  constructor(private client: Client) { }

  public save(txdData): Observable<any> {
    return this.client.post(TXD, txdData);
  }

  public getTxdsByRouteTxTypeAndDate(date: string, route: string, txType: string): Observable<any> {
    return this.client.get(`${TXD}/byRouteTxTypeAndDate?date=${date}&route=${route}&txType=${txType}`);
  }

  public processPayments(batch:boolean,date:string,facNo:number,route?:string,supplier?:string):Observable<any> {
    if(batch) return this.client.get(`${TXD}/processPay?batch=${batch}&facNo=${facNo}&date=${date}`);
    return this.client.get(`${TXD}/processPay?batch=${batch}&route=${route}&supplier=${supplier}&facNo=${facNo}&date=${date}`);
  }

  public saveLoan(loan:any): Observable<Loan>{
    return <Observable<Loan>> <unknown>this.client.post(LOAN,loan);
  }

  public getLoansByRouteAndSupplier(route: String,supplier:String): Observable<any>{
    return this.client.get(`${LOAN}/${route}/${supplier}`);
  }

  public getTxdsWithPagination(route: String,supplier:String,txType:string,page: number, pageSize: number,date?:String): Observable<TxdFetchType> {
    return <Observable<TxdFetchType>> this.client.get(`txds/${route}/${supplier}?txType=${txType}&page=${page}&pageSize=${pageSize}`);
  }

  public getTxdHistory(route: String,supplier:String,date:String): Observable<any> {
    return this.client.get(`${TXD}/history/${route}/${supplier}/${date}`);
  }
  public getTxdPay(route: String,supplier:String,date:String): Observable<any> {
    return this.client.get(`${TXD}/pay/${route}/${supplier}/${date}`);
  }

  public getTotalByCriteria(date:String,facNo:string,txType:string,route?: String,supplier?:String,): Observable<any> {
    let params = {};
    if (route) { params = { txType: txType, date: date, facNo: facNo, route: route || '', supplier: supplier || '' } }
    else { params = { txType: txType, date: date, facNo: facNo, } }
    return this.client.get(`${TXD}/getTotalByCriteria/`,{
      params : params
    });
  }

  public getTxdByCriteria(date?:String,facNo?:String,txType?:String,route?: String,supplier?:String,):Observable<Txd> {
    let params = { txType: txType, date: date, factory: facNo, route: route || '', supplier: supplier || '' };
    return this.client.get(`${TXD}/byCriteria`,{ params : params});
  }

  public createOne(txdData): Observable<any> {
    return this.client.post(`txds`, txdData);
  }

  filterTxd(route: string,txType: string,status: string,month: any,query:string,page: number, pageSize: number): Observable<TxdFetchType> {
    return <Observable<TxdFetchType>> this.client.get(`${TXD}?route=${route}&txType=${txType}&status=${status}&month=${month}&page=${page}&pageSize=${pageSize}`);
  }
  updateTxd (txd: Txd[]): Observable<Txd> {     
    console.log('approve service')       
    return <Observable<Txd>> this.client.put(`txds/approve`, txd);     
  }
}
