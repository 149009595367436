import { Injectable } from '@angular/core';
import { Client } from './client.service';
import { User } from '../model/user';
import { UsrRole } from '../model/usrRole';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private client: Client) { }

    getDummyUser(): User {
      return {
        _id: '',
        username: '',
        password: '',
        roles: [],
        factory: {
          _id: '',
          facNo: '',
          address1: '',
          address2: '',
          address3: '',
          address4: '',
          company: ''},
        fname: '',  
        lname: '',  
        created: new Date()
      };
    }

    addUser (user: User): Observable<User> {            
      return <Observable<User>> <unknown>this.client.post(`users`, user);
    }

    updateUser (user: User): Observable<User> {            
      return <Observable<User>> <unknown>this.client.put(`users`, user);     
    }

    deleteUser(user: User) {
      return <Observable<User>> <unknown>this.client.delete(`users/${user._id}`);     
    }

     /** GET hero by id. Will 404 if id not found */
    getUser(name: String): Observable<User> {
      return <Observable<User>> this.client.get(`users/${name}`);
    }

    getUsers(pageNum: number, limit: number): Observable<{users: any[], count: number, page: number, pages: number }> {      
      const url = (pageNum) ? `users?page=${pageNum}` : `users`;
      return <Observable < { users: any[], count: number, page: number, pages: number }>>this.client.get(url);      
    }

    getUserFactoryAndDate(): {date: Date, facNo: number} {
      return JSON.parse(localStorage.getItem('user_data'));
    }

    getUsrRoles(): Observable<UsrRole[]> {
      return <Observable<UsrRole[]>> this.client.get('usrRoles');
    }
}
